import { useStaticQuery, graphql } from "gatsby"

export const Data = () => {
  const { contentfulCategories } = useStaticQuery(
    graphql`
      query {
        contentfulCategories {
          names
        }
      }
    `
  )
  return contentfulCategories
}
