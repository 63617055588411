import React from "react"
import { styled } from "linaria/react"
import Img from "gatsby-background-image"
import Link from "gatsby-link"

const TextWrapper = styled.div`
  @media screen and (max-width: 480px) {
    left: 10px;
    bottom: 10px;
  }
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  a {
    @media screen and (max-width: 480px) {
      display: none;
    }
    color: #fff;
    font-weight: 300;
    font-size: 20px;
    text-decoration: underline;
  }
  h4 {
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 5px;
    text-decoration: none;
  }
`

const CategoryWrapper = styled(Img)`
  @media screen and (max-width: 1024px) and (min-width: 900px) {
    flex-basis: 33.33%;
    height: 315px;
  }
  @media screen and (max-width: 900px) {
    height: 280px;
    flex-basis: 50%;
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    flex-basis: 50%;
    width: 50%;
    height: 220px;
  }
  height: 465px;
  overflow: hidden;
  width: 100%;
  position: relative;
  transition: filter 0.4s ease-in-out;
  &:hover {
    filter: saturate(0%);
  }
  &:after,
  &:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
`

const SquareCategory = props => {
  const background = [
    props.image,
    `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)`,
  ].reverse() // Make the linear gradient appear over the image
  return (
    <CategoryWrapper fluid={background}>
      <TextWrapper>
        <h4>{props.category}</h4>
        <Link to={props.link}>{props.linkText}</Link>
      </TextWrapper>
    </CategoryWrapper>
  )
}

export default SquareCategory
