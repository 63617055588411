import React from "react"
import { styled } from "linaria/react"
import Category from "../../Elements/SquareCategory"
import { colors } from "../../theme"

import { Data } from "../../../Hooks/WebsiteData"
import Link from "gatsby-link"
import Arrow from "../../../images/Icons/mdi_arrow_forward.svg"

const Categories = styled.div`
  @media screen and (max-width: 480px) {
    margin-top: 0;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 6;
  margin-top: -70px;
`
const CategoryWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
`
const Top = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.div`
  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
    height: 55px;
    width: 100%;
  }
  background: ${colors.black};
  padding-left: 55px;
  padding-right: 55px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
  }
`

const MobileCTA = styled.div`
  flex-direction: column;
  padding: 10px;
  span {
    margin-top: auto;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 20px;
  }
  display: none;
  height: 220px;
  width: 50%;
  background: ${colors.mainPink};
  border: 1px solid rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 1024px) and (min-width: 900px) {
    flex-basis: 33%;
    height: 315px;
    display: flex;
    padding: 15px;
    span {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 480px) {
    height: 280px;
    display: flex;
    span {
      font-size: 24px;
      line-height: 36px;
    }
  }
  @media screen and (max-width: 480px) {
    display: flex;
  }
`

const StyledLink = styled(Link)`
  @media screen and (max-width: 1024px) and (min-width: 900px) {
    font-size: 30px;
    line-height: 40px;
    > img {
      width: 40px;
      height: 40px;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 480px) {
    font-size: 24px;
    line-height: 36px;
    > img {
      width: 40px;
      height: 40px;
    }
  }
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  text-decoration: underline;
  color: ${colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CategoriesIntro = props => {
  const categories = props.categories.categories
  const data = Data()
  const posts = categories.map((category, index) => (
    <Category
      image={category.fluid}
      link={"/blog"}
      category={data.names[index]}
    />
  ))
  return (
    <Categories>
      <Top>
        <Title>
          <h3>Things I talk about: </h3>
        </Title>
      </Top>
      <CategoryWrapper>
        {posts}
        <MobileCTA>
          <span>
            And Much
            <br /> More
          </span>
          <StyledLink to={"/blog/"}>
            Check It Out <img src={Arrow} alt="forward arrow" />
          </StyledLink>
        </MobileCTA>
      </CategoryWrapper>
    </Categories>
  )
}

export default CategoriesIntro
