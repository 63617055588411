import { useStaticQuery, graphql } from "gatsby"

export const HomeImages = () => {
  const { contentfulHomeImages } = useStaticQuery(
    graphql`
      query {
        contentfulHomeImages {
          hero {
            title
            fluid(maxWidth: 2000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          about {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          blog {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          categories {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    `
  )
  return contentfulHomeImages
}
