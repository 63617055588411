import React from "react"
import { styled } from "linaria/react"
import Button from "../../Elements/Button"
import HelpBox from "../../Elements/HelpBox"
import iconEducation from "../../../images/Icons/MissionIntro/Icon-Education.svg"
import iconEvents from "../../../images/Icons/MissionIntro/Icon-Events.svg"
import iconPackage from "../../../images/Icons/MissionIntro/Icon-Package.svg"

const Wrapper = styled.section`
  transition: all 0.2s linear;
  display: flex;
  flex-direction: column;
  margin: 250px 55px;
  text-align: center;
  h2 {
    font-size: 45px;
    text-transform: uppercase;
  }
  span {
    line-height: 30px;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
  }
  > p {
    max-width: 750px;
    font-size: 16px;
    line-height: 30px;
    margin: 25px auto 50px auto;
    font-weight: 300;
    @media screen and (max-width: 480px) {
      padding: 0 15px;
      font-size: 14px;
      line-height: 28px;
    }
  }
  > img {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1180px) {
    padding: 0 15px;
    margin: 250px 0;
  }
  @media screen and (max-width: 1024px) and (min-width: 480px) {
    margin: 100px 0;
  }
  @media screen and (max-width: 480px) {
    margin: 50px 0px;
    padding: 0 0;
    h2 {
      font-size: 35px;
      margin-bottom: 10px;
    }
    span {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 300;
    }
  }
`
const Divider = styled.div`
  @media screen and (max-width: 480px) {
    padding: 0 15px;
  }
  width: 100%;
  display: flex;
  align-items: center;
`

const DivText = styled.h3`
  @media screen and (max-width: 480px) {
    font-size: 24px;
    min-width: 155px;
    margin: auto 15px;
  }
  width: min-content;
  white-space: nowrap;
  margin: 0 40px;
  text-transform: uppercase;
  font-size: 35px;
`

const DivLine = styled.div`
  height: 1px;
  width: 100%;
  background: #000;
`

const Container = styled.div`
  @media screen and (max-width: 900px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 60px 30px;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    margin: 20px 0;
  }
  padding: 80px 0;
  display: flex;
  > * {
    margin: 0px 20px 0px 0;
  }
  > *:nth-child(3) {
    margin: 0px 0 0px 0;
  }
`

const Padding = styled.div`
  @media screen and (max-width: 480px) {
    padding: 0 30px 0 0;
    display: block;
  }
  @media screen and (max-width: 900px) and (min-width: 480px) {
    padding: 55px;
  }
  display: none;
`
const MissionIntro = props => {
  return (
    <Wrapper>
      <h2>K.A.T.E.</h2>
      <span>
        KEEPING ALL TROOPS <b>ENCOURAGED</b>
      </span>
      <p>{props.body}</p>
      <Divider>
        <DivLine />
        <DivText>How I Help</DivText>
        <DivLine />
      </Divider>
      <Container>
        <HelpBox
          img={iconEducation}
          alt="Stack of books"
          title="Education"
          body={props.education}
        />
        <HelpBox
          img={iconEvents}
          alt="Megaphone"
          title="Events"
          body={props.events}
        />
        <HelpBox
          img={iconPackage}
          alt="Open package with hearts"
          title="Care Packages"
          body={props.package}
        />
        <Padding />
      </Container>
      <Button text="Find Out More" link="/mission/" margin="auto" />
    </Wrapper>
  )
}

export default MissionIntro
