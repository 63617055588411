import React from "react"
import { styled } from "linaria/react"
import Button from "../../Elements/Button"
import Img from "gatsby-image"

const Wrapper = styled.section`
  @media screen and (max-width: 480px) {
    flex-direction: column-reverse;
    margin-top: 100px;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  max-width: 440px;
  margin: auto;
  h2 {
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 50px;
    font-weight: 300;
  }
  span {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0 15px;
    p {
      font-size: 14px;
      line-height: 28px;
    }
    h2 {
      font-size: 30px;
    }
  }
`

const Image = styled(Img)`
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1024px) {
  }
  background: #ccc;
  width: 45%;
  margin: auto;
`

const Divider = styled.div`
  height: 1px;
  background: #000;
  width: 100px;
  margin-bottom: 20px;
`

const AboutIntro = props => {
  return (
    <Wrapper>
      <Text>
        <span>Hi,</span>
        <h2>I'm Katelyn</h2>
        <Divider />
        <p>{props.body}</p>
        <Button text="About Me" link="/about/" style={{ width: "100%" }} />
      </Text>
      <Image fluid={props.fluid} alt="Portrait of Katelyn Fedrick" />
    </Wrapper>
  )
}

export default AboutIntro
