import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Hero from "../components/Sections/BasicHero"
import AboutIntro from "../components/Sections/Home/AboutIntro"
import BlogIntro from "../components/Sections/Home/BlogIntro"
import MissionIntro from "../components/Sections/Home/MissionIntro"
import Subscription from "../components/Sections/Subscription"
import Feed from "../components/Sections/SocialFeed"
import { HomeImages } from "../Hooks/HomeImages"

const IndexPage = () => {
  const { hero, about, blog, ...categories } = HomeImages()
  return (
    <Layout>
      <SEO
        title="Home"
        description="Katelyn Fedrick Lifestyle Blog. Fashion, Adventure, Recipes, Beauty and everything in between."
      />
      <Hero
        fluid={hero.fluid}
        title="Help Support My Mission"
        body="Find out more about my mission Keeping All Troops Encouraged (K.A.T.E), ways to help support, and fun activities you can do to get involved for free."
        buttonText="Find out More"
        link="/mission/"
      />
      <AboutIntro
        body="Thanks for stopping by! A few things to know about me are that I love going on adventures, baking, serving my community, working on my lifestyle and fitness, and competing in pageants. Follow along to learn more about me."
        fluid={about.fluid}
      />
      <BlogIntro
        body="Watch my journey unfold and stay updated with the latest trends including fashion, beauty, and delicious recipes."
        fluid={blog.fluid}
        categories={categories}
      />
      <MissionIntro
        body="I come from a family with a strong military background and have grown up appreciating how valuable each soldier and veteran is. As a result, I founded my mission project K.A.T.E in 2016 as a way to give back to soldiers and veterans. A few of the ways I do my part to give back are by distributing thank you cards, collecting items for care packages, and attending events supporting our soldiers."
        education="A simple thank you can go a long way. For example, I create and collect thank you cards to give to soldiers and veterans as a token of my gratitude. My goal in doing so is to inspire others to share a passion for helping others and show my appreciation towards our soldiers and veterans."
        events="Volunteering at events that raise support and/or awareness for our soldiers and veterans is one of the best ways to show your appreciation. Click the link to see the upcoming events supporting our soldiers and veterans."
        package="Beginning in 2020, I started a care package drive to send to active soldiers overseas. This is a simple and practical way to engage and help our men and women serving our country. Follow along to see what items are most needed and where to send them."
      />
      <Subscription body="Be the first to know about new blog posts and get the latest updates about my mission project." />
      <Feed />
    </Layout>
  )
}

export default IndexPage
