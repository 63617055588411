import React from "react"
import { styled } from "linaria/react"
import { colors } from "../theme"

const BoxWrapper = styled.div`
  width: 100%;
  padding: 50px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  p {
    text-align: center;
    margin-bottom: 0;
    margin-top: 50px;
  }
  @media screen and (max-width: 480px) {
    min-width: 95%;
    scroll-snap-align: center;
    p {
      font-size: 14px;
      line-height: 28px;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 480px) {
    min-width: 55%;
  }
`

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
`

const BoxHeader = styled.div`
  height: 60px;
  margin-top: -80px;
  margin-bottom: 50px;
  background: ${colors.black};
  display: flex;
  width: max-content;
  flex-direction: column;
  justify-content: center;
  padding: 0 25px;
  min-width: 70%;
  span {
    width: 100%;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    margin: auto;
  }
`

const HelpBox = props => {
  return (
    <BoxWrapper>
      <BoxHeader>
        <span>{props.title}</span>
      </BoxHeader>
      <StyledImage src={props.img} alt={props.alt} />
      <p>{props.body}</p>
    </BoxWrapper>
  )
}

export default HelpBox
