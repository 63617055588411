import React from "react"
import { styled } from "linaria/react"
import { colors } from "../../theme"
import Img from "gatsby-image"

import Button from "../../Elements/Button"
import Categories from "./CategoriesIntro"

const Wrapper = styled.section`
  @media screen and (max-width: 480px) {
    margin: 100px 0 0 0;
  }
  margin: 250px 0 0 0;
  display: flex;
  flex-direction: column;
`

const IntroWrapper = styled.div`
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0 15px 150px 15px;
  }
  @media screen and (max-width: 1280px) {
    padding-bottom: 150px;
  }
  background: ${colors.mainPink};
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 5;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 70px;
`
const TextContainer = styled.div`
  width: 45%;
  margin-left: 80px;
  margin-top: 65px;
  margin-right: 55px;
  h2 {
    font-size: 35px;
    font-weight: 300;
    margin-bottom: 25px;
    text-transform: uppercase;
    b {
      font-weight: 600;
    }
  }
  p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 55px;
    font-weight: 300;
  }
  @media screen and (max-width: 480px) {
    p {
      font-size: 14px;
      line-height: 28px;
    }
    h2 {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 0;
    padding: 0px 0;
  }
  @media screen and (max-width: 1280px) and (min-width: 900px) {
    margin-left: 50px;
    margin-top: 50px;
    margin-right: 25px;
  }
`

const ImageWrapper = styled(Img)`
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 0;
    max-height: 500px;
    bottom: 25px;
  }
  @media screen and (max-width: 1280px) and (min-width: 901px) {
    margin-left: 25px;
    min-height: 0;
    width: 50%;
    bottom: 25px;
  }
  img {
    @media screen and (max-width: 900px) {
      object-fit: cover;
      object-position: top center;
    }
    @media screen and (max-width: 1280px) and (min-width: 901px) {
      object-fit: cover;
      object-position: top left;
    }
    object-fit: cover;
    object-position: center center;
  }
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  width: 45%;
  max-height: 600px;
  margin-left: 55px;
  bottom: 55px;
`

const Divider = styled.div`
  height: 1px;
  background: #000;
  width: 100px;
  margin-bottom: 25px;
`

const BlogIntro = props => {
  return (
    <Wrapper>
      <IntroWrapper>
        <ImageWrapper
          imgStyle={{ objectFit: "", objectPosition: "" }}
          fluid={props.fluid}
        />
        <TextContainer>
          <h2>
            <b>Sharing My Journey</b> and Adventures in life.
          </h2>
          <Divider />
          <p>{props.body}</p>
          <Button
            text="Visit my blog"
            link="/blog/"
            style={{ width: "100%" }}
          />
        </TextContainer>
      </IntroWrapper>
      <Categories categories={props.categories} />
    </Wrapper>
  )
}

export default BlogIntro
